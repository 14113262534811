import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

import { isIframeView } from '@common/helpers/appLayoutHelper';

export const Root = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin: 0;
  text-align: center;
  background: inherit;
  background-color: ${props => props.theme.primaryBackground};
  box-shadow: 0 -3px 3px 0 rgb(0 0 0 / 16%);
`;

export const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  color: ${props => props.theme.textPrimary};

  & svg {
    color: ${props => props.theme.textPrimary};
  }

  & svg > path {
    fill: ${props => props.theme.textPrimary};
  }

  & .mi-ticket {
    top: ${({ theme }) => theme.space_1};
  }

  & .mi-euro-circle {
    top: -1px;
  }
`;

export const disabledItemsContainerStyles = `
  &.footer-item {
    opacity: 0.25;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const ItemContainer = styled.div<{
  isDisabled: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-content: center;
  justify-items: center;
  box-sizing: border-box;
  width: calc(100vw / ${isIframeView() ? '4' : '5'});
  padding-bottom: ${({ theme }) => theme.space_5};
  height: 68px;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.textPrimary};

  svg {
    position: relative;
  }

  &.active {
    & svg {
      fill: ${props => props.theme.textActive};
    }

    & svg > path {
      fill: ${props => props.theme.textActive};
      stroke: ${props => props.theme.textActive};
    }

    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.textActive};
  }

  &.cashout svg {
    position: relative;
  }

  &.cashout .badge {
    border: ${({ theme }) => theme.border};
    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.textActive};
    z-index: 10;

    + svg {
      left: -5px;
    }
  }

  &.bettingSlip .footer-icon svg {
    transform: translateX(1px);
  }

  &.bettingSlip .footer-icon path {
    stroke: none;
    fill: ${props => props.theme.textPrimary};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &.bettingSlip.active .footer-icon path {
    fill: ${props => props.theme.textActive};
  }

  &.bettingSlip {
    & .badge {
      border: ${({ theme }) => theme.border};
      background-color: ${props => props.theme.accent};
      color: ${props => props.theme.textActive};
      z-index: 10;

      + svg {
        left: -5px;
      }
    }

    &.active .badge {
      background-color: ${props => props.theme.textActive};
      color: ${props => props.theme.textPrimary};
      border: ${({ theme }) => theme.border};
    }
  }

  &.cashout.active .badge {
    background-color: ${props => props.theme.textActive};
    color: ${props => props.theme.textPrimary};
    border: ${({ theme }) => theme.border};
  }

  ${({ isDisabled }) => isDisabled && disabledItemsContainerStyles}
`;

export const Badge = styled(TextSM)`
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  top: -1px;
  right: -10px;
  background-color: ${props => props.theme.accent};
  border-radius: 50%;
  color: ${props => props.theme.textActive};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Label = styled(TextSM)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontBold};
`;
