/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div<{ isTransparentDetail: boolean }>`
  position: relative;
  width: 100%;
  min-width: 110px;

  #bet_detail {
    background-color: ${({ isTransparentDetail, theme }) =>
      isTransparentDetail ? 'transparent' : theme.main};
    ${({ theme }) =>
      theme.isDesktop &&
      'overflow-y: auto; height: calc(100vh - 60px); overflow-x: hidden;'}

    .bets-container {
      border-bottom: 5px solid ${props => props.theme.secondary};
      ${({ theme }) => theme.isDesktop && 'border: none;'}
    }
  }

  & .generic-scoreboard-last-part ul::after {
    content: ' ';
    box-shadow: rgb(0 0 0 / 35%) -8px 0 8px 4px;
    background: transparent;
    border-radius: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 18px;
    clip: rect(auto, -12px, auto, -20px);
  }

  .cashout-container {
    display: grid;
    color: ${({ theme }) => theme.highlightWrapperBackground};
    grid-auto-flow: column;
    height: 100%;
  }

  .cashout-reverse-bg {
    grid-auto-flow: row;
    background-color: ${({ theme }) => theme.textActive};
  }

  .betDetailCashoutButton {
    margin-top: ${({ theme }) => theme.space_15};
  }

  .part-cashout {
    margin-left: ${({ theme }) => theme.space_10};
    margin-right: ${({ theme }) => theme.space_10};
  }
`;
