import styled from 'styled-components';

import { getHeaderHeight } from '@features/app/layout/header';

const headerHeight = getHeaderHeight();

const getRootPadding = () => {
  return `padding-top: ${headerHeight}px;`;
};

export const Root = styled.div<{
  hideHeader: boolean;
  removeMaxHeight: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  max-width: 1920px;
  margin: 0 auto;
  height: calc(
    100vh - ${({ hideHeader }) => (hideHeader ? '0' : headerHeight)}px
  );

  ${({ hideHeader }) => !hideHeader && getRootPadding()}

  ${({ removeMaxHeight }) =>
    removeMaxHeight
      ? ''
      : '@media screen and (min-width: 2100px) { height: auto;};'};

  &.fullWidth {
    max-width: unset;
  }
`;

export const AppHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexHeader};
  background-color: ${props => props.theme.highlightsHeaderBackground};
`;

const getTopStyle = (isMarginTop: boolean, theme) => {
  return `${isMarginTop ? 'margin-top' : 'padding-top'}: ${theme.space_15};`;
};

export const MainContent = styled.div<{
  isMarginTop: boolean;
  isMyAccount?: boolean;
  isLeftMargin?: boolean;
  isThreeCol?: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ isThreeCol }) =>
    isThreeCol ? 'auto 1fr auto' : '1fr'};
  ${({ isMarginTop, theme }) => getTopStyle(isMarginTop, theme)}

  box-sizing: border-box;
  background-color: ${({ isMyAccount, theme }) =>
    isMyAccount ? theme.textActive : theme.wrapperBackground};
  margin-left: ${({ isLeftMargin, theme }) =>
    isLeftMargin ? theme.space_20 : '0'};
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.fullHeight {
    padding-top: 0;
    margin-top: 0;
  }
`;

const betsCenterWrapper = `
  max-width: 800px;
  width: 800px;
  justify-self: flex-end;
  
  @media (max-width: 1355px) {
    width: 730px;
  }
`;

const getLimitsMinHeight = (topNotificationHeight: number) => `
min-height: calc(1400px + ${topNotificationHeight}px);
`;

const limitsLockMaxHeight = `
min-height: 550px;
`;

const centerFullHeightStyles = `height: 100vh;`;

export const CenterWrapper = styled.div<{
  isFullHeight: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 640px;
  ${({ isFullHeight }) => isFullHeight && centerFullHeightStyles};
`;

export const CenterPage = styled.div<{
  isBets?: boolean;
  isChangePassword?: boolean;
  isLimits?: boolean;
  limitsLock?: boolean;
  topNotificationHeight?: number;
  removeMaxHeight?: boolean;
}>`
  justify-self: center;
  width: ${({ isChangePassword }) => (isChangePassword ? '705px' : '446px')};
  min-height: 300px;

  ${({ removeMaxHeight }) => !removeMaxHeight && 'max-height: 800px;'}
    ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  ${({ isLimits, topNotificationHeight }) =>
    isLimits && getLimitsMinHeight(topNotificationHeight || 0)};
  ${({ limitsLock }) => limitsLock && limitsLockMaxHeight};
  ${({ isBets }) => isBets && betsCenterWrapper}

  transition: all 0.3s;
`;

export const AccountSidebar = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space_40};
  width: 241px;
  background-color: ${props => props.theme.secondary};
`;

export const LeftSide = styled.div`
  padding: 0 ${({ theme }) => theme.space_15} 100px
    ${({ theme }) => theme.space_20};
  width: 360px;

  @media (max-width: 1520px) {
    width: 250px;
  }

  @media (min-width: 1280px) {
    width: 210px;
  }

  @media (min-width: 1366px) {
    width: 282px;
  }

  @media (min-width: 1600px) {
    width: 360px;
  }
`;

export const SearchWrapper = styled.div`
  background-color: transparent;
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const RightSide = styled.div`
  min-width: 360px;
  min-height: auto;
  align-self: start;
  padding: 0 ${({ theme }) => theme.space_20} 0 ${({ theme }) => theme.space_15};
  height: calc(100vh - 75px);
  position: sticky;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;

  ::-webkit-scrollbar {
    width: 0;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  @media (min-width: 1280px) {
    max-width: 210px;
  }

  @media (min-width: 1366px) {
    max-width: 282px;
  }

  @media (min-width: 1600px) {
    max-width: 360px;
  }
`;

export const PartnersStaticContent = styled.div`
  #official-partners {
    background: ${({ theme }) => theme.textActive};
    cursor: pointer;
  }

  #official-partners .inner {
    padding: 0;
    text-align: left;

    h3 {
      display: none;
    }

    .image-list-items {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: space-around;
      padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
      background: ${({ theme }) => theme.textActive};

      img {
        margin: ${({ theme }) => theme.space_10};
      }
    }
  }
`;

export const PartnersStaticContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${({ theme }) => theme.space_15};
  background-color: ${({ theme }) => theme.main};
  align-items: center;
  text-align: center;
`;

export const PartnersHeader = styled.h3`
  font-size: ${({ theme }) => theme.fontLG};
  color: ${({ theme }) => theme.textMain};
  text-transform: uppercase;
`;

export const LiveChatButtonWrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: ${({ theme }) => theme.space_50};
  bottom: ${({ theme }) => theme.space_20};
  background-color: rgb(0 156 188);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(rgb(0 0 0 / 75%) 0 5px 10px);

  & > button {
    margin: 0;
  }
`;
