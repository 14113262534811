import styled from 'styled-components';

import { TextLarge, TextSM } from '@features/core/styles/text.styled';
import CustomLink from '@features/core/routing/CustomLink';

export const Root = styled.div`
  width: 100%;
  ${({ theme }) => theme.isDesktop && 'max-width: 360px;'};
  background-color: ${({ theme }) => theme.elementNuanceColor};
  color: ${({ theme }) => theme.tertiaryBackground};
`;

const getHeaderWrapperPadding = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_20} ${theme.space_10} ${theme.space_15} ${theme.space_20};`
    : theme.space_15;
};
export const HeaderWrapper = styled.div`
  height: 20px;
  display: grid;
  align-items: center;
  padding: ${getHeaderWrapperPadding};
  justify-content: start;
  background-color: ${({ theme }) => theme.textPrimary};
`;

export const Content = styled.div`
  display: grid;
  grid-auto-flow: row;
  background-color: ${({ theme }) => theme.textPrimary};
  margin-bottom: ${({ theme }) => theme.space_5};
  font-size: ${({ theme }) => (theme.isDesktop ? theme.fontSM : theme.fontMD)};
`;

export const Link = styled(CustomLink)`
  display: inline;
  text-decoration: underline;
`;

export const SummaryHeader = styled(TextLarge)`
  color: ${({ theme }) => theme.tertiaryBackground};
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
`;

export const getAmountBorderStyles = borderColor => `
  border-bottom: 1px solid ${borderColor};
`;

const getAmountDataPadding = ({ theme }) => {
  return theme.isDesktop
    ? `0 ${theme.space_20} ${theme.space_5} ${theme.space_20};`
    : `0 ${theme.space_15} ${theme.space_5} ${theme.space_15};`;
};

export const AmountData = styled.div<{ withBorder?: boolean }>`
  padding: ${getAmountDataPadding};
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ theme }) => theme.space_10};
  ${({ withBorder, theme }) =>
    withBorder && getAmountBorderStyles(theme.textMain)};
`;

export const AmountRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.tertiaryBackground};
  max-height: 25px;
  align-items: center;

  p {
    font-weight: ${({ theme }) => theme.fontRegular};
  }

  &:nth-child(2) {
    margin-bottom: ${({ theme }) => theme.space_20};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.total {
    align-content: center;
    padding-top: ${({ theme }) =>
      theme.isDesktop ? theme.space_10 : theme.space_5};
  }
`;

export const LineDivider = styled.div`
  margin: ${({ theme }) => theme.space_5}
    ${({ theme }) => (theme.isDesktop ? theme.space_20 : theme.space_15)};
  height: ${({ theme }) => theme.space_1};
  background: ${props => props.theme.buttonMainTextColor};
`;

export const Label = styled.p`
  margin: 0;

  &.sum {
    font-weight: ${({ theme }) => theme.fontBold};
  }
`;

const getWarningMsgPadding = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_25} ${theme.space_10};`
    : `${theme.space_20} ${theme.space_10} ${theme.space_25};`;
};
export const WarningMessage = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  padding: ${getWarningMsgPadding};
`;

export const InfoData = styled(TextSM)`
  text-align: center;

  &:first-child {
    margin-bottom: ${({ theme }) => theme.space_5};
  }
`;

const getButtonContainerPadding = ({ theme }) => {
  return theme.isDesktop
    ? theme.space_20
    : `${theme.space_15} ${theme.space_10};`;
};
export const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  background-color: ${({ theme }) => theme.textPrimary};
  padding: ${getButtonContainerPadding};
  box-shadow: 0 -3px 3px 0 rgb(0 0 0 / 16%);
`;

export const Loader = styled.div`
  padding: ${({ theme }) => theme.space_10};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
`;
