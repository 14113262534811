/**
 * node-convict schema for pow.
 *
 * https://github.com/mozilla/node-convict
 */

/* eslint-disable check-file/filename-naming-convention */

module.exports = {
  // Configurations that affects building
  proxyUrl: {
    doc: 'Proxy url for querying',
    default: process.env.PROXY_URL || 'http://localhost',
    format: String,
  },

  depositLimits: {
    doc: 'Bet Limits',
    default: process.env.DEPOSIT_LIMITS || 1000,
    format: Number,
  },

  proxyPort: {
    doc: 'Base port',
    default: process.env.PORT || 4000,
    format: Number,
  },

  graphUrl: {
    doc: 'Graph url for querying',
    default: process.env.GRAPH_URL || 'http://localhost:4000/api/graphql',
    format: String,
  },

  hasGraphqlCache: {
    doc: 'is graphql cache enabled?',
    default: process.env.HAS_GRAPHQL_CACHE || true,
    format: Boolean,
  },

  socketClusterUrl: {
    doc: 'socket cluster url for updating events',
    default: process.env.SOCKET_URL || 'fe-socketcluster.sportwetten.de',
    format: String,
  },

  socketUrl: {
    doc: 'socket cluster url for updating events',
    default: process.env.SOCKET_IO_URL || 'wss://webdata1.spbk.bet',
    format: String,
  },

  betRmSocketUrl: {
    doc: 'URL for bets updater including socket port',
    default: process.env.BETRM_URL || 'wss://betrm.sportwetten.de/socket',
    format: String,
  },

  wallet_updater_host: {
    doc: 'URL for much better deposit updater',
    default: process.env.WALLET_UPDATER_HOST || 'ws-env3.masmangotest.com',
    format: String,
  },

  wallet_updater_port: {
    doc: 'Port for much better deposit updater',
    default: process.env.WALLET_UPDATER_PORT || '443',
    format: String,
  },

  wallet_updater_user: {
    doc: 'User for much better deposit updater',
    default: process.env.WALLET_UPDATER_USER || 'mobile',
    format: String,
  },

  wallet_updater_pass: {
    doc: 'Pass for much better deposit updater',
    default: process.env.WALLET_UPDATER_PASS || 'Ong4choo4jieN9yiekah1shui',
    format: String,
  },

  eventServiceUrl: {
    doc: 'Eventservice uri to events api endpoint',
    default:
      process.env.EVENTSERVICEURL ||
      'https://eventservice.sportwetten.de/de/v1/events',
    format: String,
  },

  hasHomePage: {
    doc: 'is home page enabled?',
    default: process.env.HAS_HOME_PAGE || true,
    format: Boolean,
  },

  hasCashoutFavorites: {
    doc: 'is cashout favotires enabled?',
    default: process.env.HAS_CASHOUT_FAVORITES || true,
    format: Boolean,
  },

  hasCategoriesFavorites: {
    doc: 'is categories favotires enabled?',
    default: process.env.HAS_CATEGORIES_FAVORITES || true,
    format: Boolean,
  },

  hasAutoWithdrawal: {
    doc: 'is automatic withdrawal enabled?',
    default: process.env.HAS_AUTO_WITHDRAWAL || false,
    format: Boolean,
  },

  hasCasino: {
    doc: 'is casino page enabled?',
    default: process.env.HAS_CASINO || false,
    format: Boolean,
  },

  hasBonus: {
    doc: 'Bonus page after registration enabled?',
    default: process.env.HAS_BONUS || false,
    format: Boolean,
  },

  hasGdpr: {
    doc: 'is gdpr page enabled?',
    default: process.env.HAS_GDPR || true,
    format: Boolean,
  },

  hasUploads: {
    doc: 'is uploads page enabled?',
    default: process.env.HAS_UPLOADS || false,
    format: Boolean,
  },
  hasLMTPage: {
    doc: 'is LMT page enabled?',
    default: process.env.HAS_LMT_PAGE || true,
    format: Boolean,
  },
  hasBetPacker: {
    doc: 'is Bet Builder page enabled?',
    default: process.env.HAS_BET_BUILDER || false,
    format: Boolean,
  },
  hasTicketScanner: {
    doc: 'is ticket scanner enabled ?',
    default: process.env.HAS_TICKET_SCANNER || true,
    format: Boolean,
  },
  hasManualBarcode: {
    doc: 'is manual barcode enabled?',
    default: process.env.HAS_MANUAL_BARCODE || true,
    format: Boolean,
  },
  hasTestListModal: {
    doc: 'is test list modal enabled?',
    default: process.env.HAS_TEST_LIST_MODAL || false,
    format: Boolean,
  },
  hasFullRegistration: {
    doc: 'is full registration enabled?',
    default: process.env.HAS_FULL_REGISTRATION || true,
    format: Boolean,
  },
  hasCheckMissUserData: {
    doc: 'is missing user data check enabled?',
    default: process.env.HAS_CHECK_MISS_USER_DATA || true,
    format: Boolean,
  },

  hasStaticPages: {
    doc: 'are static pages enabled?',
    default: process.env.HAS_STATIC_PAGES || true,
    format: Boolean,
  },

  hasLimits: {
    doc: 'is limits&selfexclusion page enabled?',
    default: process.env.HAS_LIMITS || true,
    format: Boolean,
  },

  hasDeposits: {
    doc: 'are depoosit pages enabled?',
    default: process.env.HAS_DEPOSITS || true,
    format: Boolean,
  },

  hasWithdrawals: {
    doc: 'are withdrawal pages enabled?',
    default: process.env.HAS_WITHDRAWALS || true,
    format: Boolean,
  },

  hasEventMarkets: {
    doc: 'are event detailed markets enabled ?',
    default: process.env.HAS_EVENT_MARKETS || true,
    format: Boolean,
  },

  hasTotalOddsNotifier: {
    doc: 'are total odds notifier enabled ?',
    default: process.env.HAS_TOTAL_ODDS || true,
    format: Boolean,
  },

  hasFavorites: {
    doc: 'are favorites enabled?',
    default: process.env.HAS_FAVORITES || false,
    format: Boolean,
  },

  hasAlarms: {
    doc: 'are alarms enabled?',
    default: process.env.HAS_ALARMS || false,
    format: Boolean,
  },

  hasAlarmReasons: {
    doc: 'are alarms reasons enabled?',
    default: process.env.HAS_ALARM_REASONS || false,
    format: Boolean,
  },

  hasSportradarWidgetLMT: {
    doc: 'is sportradar lmt widget enabled?',
    default: process.env.HAS_LMT || true,
    format: Boolean,
  },

  hasSportradarWidgetTable: {
    doc: 'are sportradar tables widget enabled?',
    default: process.env.HAS_SPORTRADAR_WIDGET_TABLE || true,
    format: Boolean,
  },

  hasSportradarWidgetLigaTable: {
    doc: 'are sportradar tables widget enabled?',
    default: process.env.HAS_SPORTRADAR_WIDGET_LIGA_TABLE || true,
    format: Boolean,
  },

  hasSportradarWidgetLastMatches: {
    doc: 'is sportradar last matches widget enabled?',
    default: process.env.HAS_LAST_MATCHES || false,
    format: Boolean,
  },

  hasSportradarIframeStatistics: {
    doc: 'is sportradar iframe enabled?',
    default: process.env.HAS_LAST_MATCHES || true,
    format: Boolean,
  },

  hasResults: {
    doc: 'are results enabled?',
    default: process.env.HAS_RESULTS || false,
    format: Boolean,
  },

  hasRegistration: {
    doc: 'is registration enabled?',
    default: process.env.HAS_REGISTRATION || true,
    format: Boolean,
  },

  hasInbox: {
    doc: 'is inbox enabled?',
    default: process.env.HAS_INBOX || false,
    format: Boolean,
  },

  hasDropDownMarkets: {
    doc: 'is dropdown markets shown?',
    default: process.env.HAS_DROPDOWN_MARKETS || true,
    format: Boolean,
  },

  theme: {
    doc: 'Theme name',
    default: process.env.THEME || 'Sportwetten-blue',
    format: String,
  },

  safechargeApiDeposits: {
    doc: 'are SC api deposits enabled?',
    default: process.env.SAFECHARGE_API_DEPOSITS || true,
    format: Boolean,
  },

  captchaKey: {
    doc: 'captcha sitekey',
    default:
      process.env.CAPTCHA_KEY || '6Lf2KkkUAAAAAKCmCFTYAar88inOW7NWz_RDm45i',
    format: String,
  },

  hasCaptcha: {
    doc: 'show or hide captcha for forms',
    default: process.env.HAS_CAPTCHA || false,
    format: Boolean,
  },

  marketingCookie: {
    doc: 'marketing cookies',
    default: process.env.MARKETING_COOKIES || [
      'atag',
      'btag',
      'affiliate_id',
      'ia_affid',
      'import_bettingslip',
      'session_id',
      'auth_token',
      'data',
    ],
    format: Array,
  },

  defaultLanguage: {
    doc: 'default language',
    default: process.env.DEFAULT_LANGUAGE || 'de',
    format: String,
  },

  allowedLanguages: {
    doc: 'allowed languages',
    default: process.env.ALLOWED_LANGUAGES || ['de', 'en'],
    format: Array,
  },

  showRemainingTimers: {
    doc: 'show/hide remaining timers',
    default: process.env.SHOW_REMAINING_TIMERS || false,
    format: Boolean,
  },

  avsApiUrl: {
    doc: 'avs api url for widget',
    default: process.env.AVS_API_URL || 'https://sw.insic.io/api',
    format: String,
  },

  avsResourceUrl: {
    doc: 'avs resource url for widget',
    default: process.env.AVS_RESOURCE_URL || 'https://sw.insic.io/frontend2',
    format: String,
  },

  hasLugas: {
    doc: 'is LUGAS regulation enabled',
    default: process.env.HAS_LUGAS || true,
    format: Boolean,
  },

  hasGlobalLimits: {
    doc: 'are global limits eabled',
    default: process.env.HAS_GLOBAL_LIMITS || true,
    format: Boolean,
  },

  hasLugasStrict: {
    doc: 'parses tags chain to find if user receive "F0002" from lugas',
    default: process.env.HAS_LUGAS_STRICT || false,
    format: Boolean,
  },

  hasNews: {
    doc: 'Should we enable news page?',
    default: process.env.HAS_NEWS || false,
    format: Boolean,
  },

  hasTestPage: {
    doc: 'Should we enable test page?',
    default: process.env.HAS_TEST_PAGE || true,
    format: Boolean,
  },

  hasWalletRequest: {
    doc: 'Do we have wallet request feature enabled?',
    default: process.env.HAS_WALLET_REQUEST || true,
    format: Boolean,
  },

  hasAnimations: {
    doc: 'Should we enable animation for components?',
    default: process.env.HAS_ANIMATIONS || true,
    format: Boolean,
  },

  hasSuspendReason: {
    doc: 'Should we show suspend reason?',
    default: process.env.HAS_SUSPEND_REASONG || true,
    format: Boolean,
  },

  hasSocketCluster: {
    doc: 'Should update events using socket cluster',
    default: process.env.HAS_SOCKET_CLUSTER || true,
    format: Boolean,
  },

  hasCreateOnlineAccount: {
    doc: 'Enables create online account process for shop customers',
    default: process.env.HAS_CREATE_ONLINE_ACCOUNT || true,
    format: Boolean,
  },

  hasYearlyDataCheck: {
    doc: 'Enables yearly data check',
    default: process.env.HAS_YEARLY_DATA_CHECK || true,
    format: Boolean,
  },

  hasAcceptedGlobal: {
    doc: 'Enable popup on login for accepting global limits',
    default: process.env.HAS_ACCEPTED_GLOBAL || true,
    format: Boolean,
  },

  live_counters: {
    doc: 'are live counters enabled?',
    default: process.env.LIVE_COUNTERS || false,
    format: Boolean,
  },

  hasEventDetailPopup: {
    doc: 'does event detail opened as popup? otherwise as separate page',
    default: process.env.EVENT_DETAIL_POPUP || true,
    format: Boolean,
  },
  hasPushNotification: {
    doc: 'is push notification enabled?',
    default: process.env.HAS_PUSH_NOTIFICATION || false,
    format: Boolean,
  },
  testUsers: {
    doc: 'Users who see test page',
    default: process.env.TEST_USERS || 'testuser1|testuser2|mofoman1|asergserg',
    format: String,
  },
  hasBonusWallets: {
    doc: 'Support for bonus wallet feature and bonus wallets on bonus page',
    default: process.env.HAS_BONUS_WALLETS || true,
    format: Boolean,
  },
  hasBonusCancellation: {
    doc: 'Support for canceling user bonus',
    default: process.env.HAS_BONUS_CANCELLATION || false,
    format: Boolean,
  },
  hasBonusCodes: {
    doc: 'Bonus codes input on bonus page',
    default: process.env.HAS_BONUS_CODES || true,
    fromat: Boolean,
  },
  hasSummaryLayer: {
    doc: 'Is summary layer enabled?',
    default: process.env.HAS_SUMMARY_LAYER || true,
    format: Boolean,
  },
  hasSmartAppBanner: {
    doc: 'Is native app advertisement banners shown?',
    default: process.env.HAS_SMART_APP_BANNER || true,
    format: Boolean,
  },
  hasPromotions: {
    doc: 'Should be enabled promotions page?',
    default: process.env.HAS_PROMOTIONS || true,
    format: Boolean,
  },
  hasSliders: {
    doc: 'Should be enabled sliders?',
    default: process.env.HAS_SLIDERS || true,
    format: Boolean,
  },
  hasOasis: {
    doc: 'Is Oasis (DE) enabled?',
    default: process.env.HAS_OASIS || true,
    format: Boolean,
  },
  hasPartnersBox: {
    doc: 'Is partners section shown?',
    default: process.env.HAS_PARTNERS_BOX || false,
    format: Boolean,
  },
  bettingslipDefaultState: {
    doc: 'Default state for IBettingslip by server name',
    default: process.env.BETTINGSLIP_DEFAULT_STATE,
    format: String,
  },
  hasCookieConsentType: {
    doc:
      'What type of cookie layer we should shown: "internal" (FE), "usercentrics" (BE) or "none" (no Cookie layer)?',
    default: process.env.HAS_COOKIE_CONSENT_TYPE || 'internal',
    format: String,
  },
  hasGoogleTagManager: {
    doc:
      'Do we enable Google Tag Manager? If yes, define the GTM key or set to empty string',
    default: process.env.HAS_GOOGLE_TAG_MANAGER || 'GTM-PMNGDQJ',
    format: String,
  },
  hasFBPixel: {
    doc: 'Do we enable face book pixel',
    default: process.env.HAS_FACEBOOK_PIXEL || '1152640965584452',
    format: String,
  },
  market_matrix: {
    doc: 'Default state for market matrix by server name',
    default: process.env.MARKET_MATRIX,
    format: String,
  },
  hasBiometricsAutologin: {
    doc: 'is user has autologin',
    default: process.env.HAS_BIOMETRIC_AUTOLOGIN || true,
    format: Boolean,
  },
  collapsedCats: {
    doc: 'Collapsed categories for mobile category tree, with shall all button',
    default: process.env.COLLAPSED_CATS || {
      4058: true,
      25936: true,
      2008: true,
      5636: true,
      5637: true,
      5638: true,
      5975: true,
      4415: true,
      5967: true,
      19149: true,
      27688: true,
      5920: true,
      12762: true,
      2409: true,
      4475: true,
      3721: true,
      18605: true,
    },
    format: Object,
  },
  excludeMarkets: {
    doc: 'Do we have markets to exclude?',
    default: process.env.EXCLUDE_MARKETS || false,
    format: Boolean,
  },
  realityPeriod: {
    doc: 'Time for reality_check period',
    default: process.env.REALITY_PERIOD || 60 * 60 * 1000,
    format: Number,
  },
  hasOddsComparison: {
    doc: 'Is odds comparison is enabled?',
    default: process.env.HAS_ODDS_COMPARISON || true,
    format: Boolean,
  },
  oddsComparisonProviders: {
    doc: 'Available providers for odds comparison',
    default: process.env.ODDS_COMPARISON_PROVIDERS || 'tipico',
    format: String,
  },
  showNumericEventCodeInEventDetail: {
    doc: 'Is showing numerical event code in eventdetail enabled?',
    default: process.env.SHOW_NUMERIC_EVENT_CODE_IN_EVENT_DETAIL || false,
    format: Boolean,
  },
  notificationEndpoint: {
    doc: 'Notification endpoint',
    default: process.env.NOTIFICATION_ENDPOINT || '/api/notifications/',
    format: String,
  },
  showDepositLimitForAll: {
    doc: 'Show deposit limit form for all user types?',
    default: process.env.SHOW_DEPOSIT_LIMIT_FOR_ALL || false,
    format: Boolean,
  },
  hasJSONRPCAuth: {
    doc: 'Use complex auth or jsonrpc auth',
    default: process.env.HAS_JSONRPC_AUTH || true,
    format: Boolean,
  },
  maxBSEvents: {
    doc: 'Maximum allowed events in BS, each event can have several selections',
    default: process.env.MAX_BS_EVENTS || 30,
    format: Number,
  },
  maxBSSelections: {
    doc: 'Maximum allowed selections in BS',
    default: process.env.MAX_BS_SELECTIONS || 30,
    format: Number,
  },
  gcmsProxyUrl: {
    doc: 'Proxy URL to the GCMS through  nginx',
    default: process.env.GCMS_PROXY_URL || '/devcdn3/api',
    format: String,
  },
  useNewScanBetApi: {
    doc: 'Use new ticket scanner api for anonymous cashout',
    default: process.env.HAS_ANONYMOUS_CASHOUT || true,
    format: Boolean,
  },
  isEmbeddedSolution: {
    doc:
      'For Iframe page we should hide specific pages and allow only few of them',
    default: process.env.IS_EMBEDDED_SOLUTION || false,
    format: Boolean,
  },
  defaultRoute: {
    doc: 'Default route (HOME page) for redirects',
    default: process.env.DEFAULT_ROUTE || '/',
    format: String,
  },
  hasBetradarAnalytics: {
    doc: 'Do we send marketing events to betradar?',
    default: process.env.HAS_BETRADAR_ANALYTICS || true,
    format: Boolean,
  },
  hasShopEMoney: {
    doc: 'Is customer card plus activated?',
    default: process.env.HAS_SHOP_E_MONEY || true,
    format: Boolean,
  },
  boomDomain: {
    doc: 'Which prod domain we are going to use?',
    default: process.env.BOOM_DOMAIN || 'www.sportwetten.de',
    format: String,
  },
  boomTranslation: {
    doc: 'Which translation files do we take for overwrites ?',
    default: process.env.BOOM_TRANSLATION || '',
    format: String,
  },
  hasMyFavorito: {
    doc: 'My favorito page is enabled',
    default: process.env.HAS_MY_FAVORITO || true,
    format: Boolean,
  },
  hasCashout: {
    doc: 'Is cashout enabled?',
    default: process.env.HAS_CASHOUT || true,
    format: Boolean,
  },
  hasMyBets: {
    doc: 'Is my bets enabled?',
    default: process.env.HAS_MY_BETS || true,
    format: Boolean,
  },
  hasLittleCashout: {
    doc: 'Is little cashout enabled?',
    default: process.env.HAS_LITTLE_CASHOUT || true,
    format: Boolean,
  }

};
