import styled from 'styled-components';

const getSearchBackground = (isHeader, bgColor) => {
  if (!isHeader) {
    return '';
  }
  return `background-color: ${bgColor};`;
};

const exclusionContainerDesktopStyles = () => `
  position: relative;
  grid-auto-flow: row;
  align-items: start;
  margin: -5px 0px;
  padding: 0px;
`;

export const SearchContainer = styled.div<{
  isHeader?: boolean;
  hasResults?: boolean;
}>`
display: grid;
  grid-auto-flow: column;
margin: -55px 0;
padding: ${({ theme }) => theme.space_10};
${({ isHeader, theme }) =>
  !isHeader && getSearchBackground(isHeader, theme.textActive)}
${({ hasResults }) => !hasResults && 'display: none;'}
${({ theme }) => theme.isDesktop && exclusionContainerDesktopStyles()}

  .search-container {
    width: 100%;
  }
`;

const exclusionResultListMobileStyles = () => `
  margin-top: -35px;
`;

const exclusionResultListDesktopStyles = () => `
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
`;

export const SearchResultsList = styled.div`
  z-index: 99;
  max-height: ${props => (props.theme.isDesktop ? '330px' : '190px')};
  overflow: auto;

  ${({ theme }) =>
    theme.isDesktop
      ? exclusionResultListDesktopStyles()
      : exclusionResultListMobileStyles()}

  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: ${props => props.theme.main};
  border-radius: 3px;
  box-shadow: ${props =>
    props.theme.isDesktop ? '0 10px 5px 5px rgb(0 0 0 / 50%)' : 'none'};
`;

export const ResultTypeTitle = styled.h3`
  color: ${props => props.theme.inputDefaultText};
  margin: ${props => props.theme.space_10};
`;

export const SearchResultsListItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  min-height: 45px;
  padding: 0 ${({ theme }) => theme.space_10};

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.highlightWrapperBackground};
  }
`;

export const Icon = styled.div`
  display: grid;
  align-items: center;
  color: ${props => props.theme.inputDefaultText};
  padding-right: ${props => props.theme.space_10};
  min-width: 18px;
`;

export const Details = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: start;
  margin: ${({ theme }) => theme.space_10} 0;
  color: ${props => props.theme.inputDefaultText};
`;

export const Title = styled.span`
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Subtitle = styled.span`
  font-size: ${props => props.theme.fontSM};
`;

export const NotFound = styled.p`
  padding-left: ${({ theme }) => theme.space_10};
  color: ${props => props.theme.selectTextColor};
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Divider = styled.div`
  height: ${({ theme }) => theme.space_1};
  background: ${props => props.theme.textActive};
`;
