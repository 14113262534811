import React from 'react';
import reduce from 'lodash/reduce';
import { Redirect, Route, Switch } from 'react-router-dom';
import includes from 'lodash/includes';

import RouteData from '@features/core/router/IRoute.types';
import routes from '@features/core/router';
import {
  commonRoutes,
  iframeUserAccountRoutes,
  loggedInRedirectToHomeRoutes,
  loggedOutRedirectToHomeRoutes,
  userAccountRoutes,
} from '@features/core/router/routeNames';
import { useServices } from '@features/core/services';
import { getPageURL, PageName } from '@features/core/routing/linkAliases';

import * as cookies from '@common/constants/cookie';
import { useUserState } from '@common/providers/user/useUserState';
import { isIframeView } from '@common/helpers/appLayoutHelper';
import { getDefaultRouteName } from '@common/providers/router/helper';

const Routes: React.FC = (): JSX.Element => {
  const { cookie } = useServices();
  const urlParams = new URLSearchParams(window.location.search);
  const token = cookie.get(cookies.TOKEN) || urlParams.get('token');
  const isLoggedIn = useUserState(state => state.isLoggedIn);
  const userRoutes = isIframeView()
    ? iframeUserAccountRoutes
    : userAccountRoutes;

  return (
    <Switch>
      {reduce(
        userRoutes,
        (acc: JSX.Element[], e: string) => {
          const route: RouteData = routes[e];
          if (token || isLoggedIn) {
            return acc.concat(
              <Route
                key={e}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />,
            );
          }
          return acc.concat(
            <Route
              key={e}
              path={route.path}
              component={route.component}
              exact={route.exact}
            >
              <Redirect to={getPageURL(PageName.LOGIN)} />
            </Route>,
          );
        },
        [],
      )}
      {reduce(
        commonRoutes,
        (acc: JSX.Element[], e: string) => {
          const route: RouteData = routes[e];
          if (route !== undefined) {
            if (includes(loggedInRedirectToHomeRoutes, e) && isLoggedIn) {
              return acc.concat(
                <Route
                  key={e}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                >
                  <Redirect to={getPageURL(getDefaultRouteName())} />
                </Route>,
              );
            }

            if (includes(loggedOutRedirectToHomeRoutes, e) && !isLoggedIn) {
              return acc.concat(
                <Route
                  key={e}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                >
                  <Redirect to={getPageURL(PageName.LOGIN)} />
                </Route>,
              );
            }

            return acc.concat(
              <Route
                key={e}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />,
            );
          }
          return acc;
        },
        [],
      )}
    </Switch>
  );
};

export default Routes;
