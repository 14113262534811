import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

const fixedStaticStyles = ({ theme }) => `
  #static-page-agb > section,
  #static-page-agb h2,
  #static-page-agb strong {
    background-color: ${theme.textMain} !important;
    font-size: ${theme.fontMD} !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    font-weight: ${theme.fontRegular};
  }

  #static-page-agb > section:first-child p {
    strong {
      display: none;
    }
    strong.static-agb-title {
      display: ${theme.isDesktop ? 'inline' : 'none'};
      text-transform: uppercase;
      font-weight: ${theme.fontBold};
    }
  }

  #static-page-agb ol,
  #static-page-agb ul {
    padding-left: 1em; 
  }
`;

export const mobileRootStyles = `
  height: 100%;
  width: 100%;
`;

export const Root = styled.div`
  width: 854px;
  height: 590px;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  overflow-y: scroll;

  ${({ theme }) => !theme.isDesktop && mobileRootStyles}
`;

const getHeaderPaddingStyles = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_30} ${theme.space_60} ${theme.space_30} ${theme.space_40}`
    : `${theme.space_15} ${theme.space_25} ${theme.space_10} ${theme.space_15}`;
};

export const Header = styled.div`
  padding: ${getHeaderPaddingStyles};
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.textMain};
`;

export const HeaderTitle = styled.h3`
  margin: 0;
  font-size: ${({ theme }) => (theme.isDesktop ? theme.fontH3 : theme.fontH1)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.accent};
`;

export const HeaderContent = styled.p`
  margin: ${({ theme }) => theme.space_15} 0 0;
`;

const getTermsPaddingStyles = ({ theme }) => {
  return theme.isDesktop
    ? `0 ${theme.space_60} ${theme.space_15} ${theme.space_40}`
    : `0 ${theme.space_25} ${theme.space_10} ${theme.space_15}`;
};

export const Terms = styled.div`
  position: relative;
  padding: ${getTermsPaddingStyles};
  color: ${({ theme }) => theme.tertiaryBackground};

  ${fixedStaticStyles};
`;

export const TermsTitleBlock = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: ${({ theme }) => theme.space_30} 0 ${({ theme }) => theme.space_20};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.textPrimary};
`;

export const TermsTitle = styled.h3`
  max-width: 500px;
  margin: 0;
  font-weight: ${({ theme }) => theme.fontRegular};
  font-size: ${({ theme }) => theme.fontH3};
`;

const getFormWrapperPadding = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_25} ${theme.space_40} ${theme.space_25}`
    : `${theme.space_20} ${theme.space_25} ${theme.space_10} ${theme.space_25}`;
};

export const FormWrap = styled.div`
  padding: ${getFormWrapperPadding};
  background-color: ${({ theme }) => theme.textActive};
  color: ${({ theme }) => theme.textMain};
`;

export const ScrollButton = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  padding: 0 !important;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    rgb(255 255 255 / 0%),
    rgb(255 251 251 / 80%) 27%,
    rgb(255 251 251 / 78%) 45%,
    ${({ theme }) => theme.highlightWrapperBackground}
  );
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  margin-top: ${({ theme }) => theme.space_30};
  color: ${({ theme }) => theme.textActive};
`;
