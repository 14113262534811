import React, { MouseEvent } from 'react';
import reduce from 'lodash/reduce';
import { useTranslation } from 'react-i18next';

import CustomLink from '@features/core/routing/CustomLink';
import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import { MENU_PANEL } from '@common/constants/dialogs';
import { HAS_TICKET_SCANNER } from '@common/constants/config';
import {
  ANIMATED_MENU,
  ANIMATED_MENU_PANEL,
} from '@common/helpers/appLayoutHelper';
import {
  closeSidebar,
  toggleAnimatedComponent,
} from '@common/providers/application/useAppState';
import { useUserState } from '@common/providers/user/useUserState';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './NavList.styled';
import { INavItem, INavList } from './NavList.types';

const onItemClick = (event: MouseEvent, item?: INavItem): void => {
  toggleAnimatedComponent(ANIMATED_MENU_PANEL);
  setTimeout(() => {
    closeSidebar(MENU_PANEL);
    toggleAnimatedComponent(ANIMATED_MENU);
    if (item && item.onClick) {
      event.preventDefault();
      item.onClick(event);
    }
  }, 300);
};

const NavList: React.FC<INavList> = ({ items }) => {
  const { t } = useTranslation();
  const isLoggedIn = useUserState(state => state.isLoggedIn);
  return (
    <S.Root data-qa="menu-main-nav">
      {services.config.get(HAS_TICKET_SCANNER) && (
        <>
          {!isLoggedIn && <S.EmptySpace />}
          <CustomLink href={PageName.TICKET_SCANNER} onClick={onItemClick}>
            <S.ScannerWrapper data-qa="scanner" isLoggedUser={isLoggedIn}>
              <S.ScannerIcon
                src={getImageBasePath('/images/barcode-scan-icon.svg')}
                alt="scanner"
              />
              {t('app-layout.menu.ticket-scanner')}
            </S.ScannerWrapper>
          </CustomLink>
        </>
      )}
      {reduce(
        items,
        (acc, item) =>
          acc.concat(
            <S.StyledCustomLink
              href={item.location}
              onClick={(event: MouseEvent): void => onItemClick(event, item)}
              key={item.key}
              activeClassName="active-item"
              dataQa={`menu-btn-${item.key}`}
            >
              <S.ItemLabel>{item.label}</S.ItemLabel>
            </S.StyledCustomLink>,
          ),
        [] as JSX.Element[],
      )}
    </S.Root>
  );
};

export default NavList;
