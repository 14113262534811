import React, { useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import endsWith from 'lodash/endsWith';

import services from '@features/core/services';
import KeyboardTarget from '@features/bettingslip/keyboardEnum';

import numeral from '@common/helpers/numeralHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { getCurrencyFromCode } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from './VirtualInput.styled';
import { IVirtualInput } from './VirtualInput.types';

const isDesktop = isDesktopView();

const VirtualInput = (props: IVirtualInput): React.ReactElement => {
  const {
    value,
    onFocus = noop,
    onBlur = noop,
    disabled,
    active,
    currentValue,
    name,
    maxValue,
    isError,
    focused = false,
    numFormat,
  } = props;

  const { domainLang } = services;
  const inputRef = useRef<HTMLInputElement>(null);
  const selectionCount = useBettingSlip(state => state.selections.length);
  const banksCount = useBettingSlip(state => state.banks.length);
  const size = useBettingSlip(state => state.size.length);
  const type = useBettingSlip(state => state.type);
  const walletType = useBettingSlip(state => state.walletType);

  const format = (num): string => {
    const separator = domainLang === 'de' ? ',' : '.';
    const reverseSeparator = domainLang === 'de' ? '.' : ',';
    const reg = new RegExp(
      `([0-9${reverseSeparator}]+)(${separator}|0${separator})?[0-9]{0,3}`,
    );
    const formatVal = (reg.exec(num) || [])[0] || '';
    const parsedValue = reg.test(num) ? formatVal : '';
    const formattedValue = numeral(parsedValue);
    // postfix converts dots or commas in user input to the appropriate language-specific separator
    const postfix =
      endsWith(parsedValue, ',') || endsWith(parsedValue, `.`) ? separator : '';
    // format large numbers with thousand separators from user input
    let numeralFormat =
      endsWith(parsedValue, `,0`) || endsWith(parsedValue, `.0`)
        ? `0,0.0[0]`
        : `0,0.[00]`;
    const regTwoDecimals = new RegExp(`\\${separator}\\d{2}$`);
    // format whole numbers to include two decimal after separator
    if (regTwoDecimals.test(parsedValue)) {
      numeralFormat = numFormat || numeralFormat;
    }
    // Check for max value
    if (maxValue && maxValue < Math.abs(Number(formattedValue.value()))) {
      return numeral(maxValue).format(numeralFormat);
    }

    const regThreeDecimals = new RegExp(`\\${separator}\\d{3}$`);
    //  rounding stake per leg with 3 decimal after separator
    if (regThreeDecimals.test(parsedValue) && name === KeyboardTarget.SINGLE) {
      return parsedValue;
    }
    return formattedValue.format(numeralFormat) + postfix;
  };

  useEffect(() => {
    if (focused) {
      inputRef?.current?.blur();
      inputRef?.current?.focus();
    }
  }, [focused, walletType]);

  useEffect(() => {
    // Requirements: when we add selections from the list, the input has to be always in focus and the user could immediately change the amount
    if (
      name !== KeyboardTarget.SINGLE &&
      name !== KeyboardTarget.AMOUNT &&
      isDesktop
    ) {
      inputRef?.current?.focus();
    }
  }, [type, banksCount, size, selectionCount]);

  const handleOnFocus = (event): void => {
    if (onFocus) {
      onFocus(format(value || event.target.value) as string);
    }
    inputRef?.current?.select();
  };

  const handleOnBlur = (event): void => {
    if (onBlur) {
      onBlur(format(event.target.value) as string);
    }
  };

  const handleOnClick = (event): void | boolean =>
    isDesktop && handleOnFocus(event);

  let additionalInputProps: {
    name: string | undefined;
    readOnly: boolean;
    onBlur?: (event) => void;
    onChange?: (event) => void;
    value: string;
  } = {
    readOnly: true,
    name,
    value: active ? format(currentValue) : format(value),
  };

  if (isDesktop) {
    additionalInputProps = {
      ...additionalInputProps,
      readOnly: false,
      onChange: handleOnBlur,
    };
  }

  return (
    <S.InputContainer
      disabled={disabled}
      active={active}
      onFocus={handleOnFocus}
      onClick={handleOnClick}
      data-qa="virtual-input"
      className="virtual_input"
      isError={isError}
    >
      <S.InputPlaceholder>
        <S.Input
          ref={inputRef}
          type="text"
          {...additionalInputProps}
          autoComplete="off"
          className="virtual_input_field"
          data-qa="virtual-input-field"
        />
        <S.InputPlaceholderCurrency>
          {getCurrencyFromCode()}
        </S.InputPlaceholderCurrency>
      </S.InputPlaceholder>
    </S.InputContainer>
  );
};

export default VirtualInput;
