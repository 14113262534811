import styled from 'styled-components';

import FavoritesButtonType from './FavoritesButton.types';

const getPageButtonStyles = (favoritesType: string) => {
  switch (favoritesType) {
    case FavoritesButtonType.sport:
      return `
                height: 100%;
                justify-content: center;
                justify-items: center;
            `;
    case FavoritesButtonType.live:
      return `
                display: flex;
                justify-content: center;
                width: 40px;
                background-color: rgb(255 255 255 / 10%);
                flex:1;
            `;
    case FavoritesButtonType.home:
      return `
                height: 15px;
            `;
    default:
      return '';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const FavoritesButton = styled.div<{ favoritesType: string }>`
  display: grid;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 481px) {
    width: 28px;
  }

  @media screen and (min-width: 481px) {
    width: 36px;
  }

  ${({ favoritesType }) => getPageButtonStyles(favoritesType)}
`;
