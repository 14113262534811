import CustomError from '@features/core/error/error';
import services from '@features/core/services';

import { IS_LOGGED_IN } from '@common/constants/cookie';
import {
  createExpiresStamp,
  TWENTY_HOURS_IN_SEK,
} from '@common/helpers/deviceUtil';
import {
  setLoggedInStatus,
  startUserAuth,
  setEndedAuth,
} from '@common/providers/user/useUserState';
import { IUser } from '@common/interfaces/user/IUser';
import getFullUserInfo from '@common/helpers/userHelper/getFullUserInfo';
import authFailure from '@common/providers/user/actions/authFailure';
import authIframeUser from '@common/providers/user/actions/authIframeUser';
import authOnlineUser from '@common/providers/user/actions/authOnlineUser';
import { isIframeView } from '@common/helpers/appLayoutHelper';
import { forwardDestination } from '@common/providers/user/actions/actionFwdDestination';

const authUser = async (): Promise<null> => {
  startUserAuth();
  setLoggedInStatus(true);
  forwardDestination();
  const expires = createExpiresStamp(TWENTY_HOURS_IN_SEK);
  try {
    let user = (await getFullUserInfo()) as IUser;
    if (user instanceof CustomError) {
      throw user;
    }

    user = {
      ...user,
      //  acceptedTAC: '0',
      //  accountFlags: '64',
      // personal_information_review: 1,
      // personal_information_status: 'show_personal_information',
    };
    services.cookie.set(IS_LOGGED_IN, 'is_logged_in', { expires });

    if (isIframeView()) {
      await authIframeUser(user);
    } else {
      await authOnlineUser(user);
    }
    setEndedAuth(true);
  } catch (e) {
    authFailure(e);
  }
  return null;
};

export default authUser;
