import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import { animated as a } from '@react-spring/web';
import { useTheme } from 'styled-components';
import { Socket } from 'phoenix';

import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import BetDetailContent from '@features/bets/components/betDetail/BetDetailsContent';

import { USER_ID } from '@common/constants/cookie';
import { BET_RM_SOCKET_URL } from '@common/constants/config';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  ANIMATED_BET_DETAIL,
  useAnimatedX,
} from '@common/helpers/appLayoutHelper';
import {
  clearBetDetail,
  setBetsSocketData,
  setSelectedBetId,
  useBets,
} from '@common/providers/bets/useBets';
import { getBetDetails } from '@common/providers/bets/helper';

import { DualRingLoader } from '@ui/components/loaders';
import { INotificationIcon } from '@ui/components/genericNotification/GenericNotification.types';
import { GenericNotification } from '@ui/components/genericNotification';

import { IBetDetail } from './BetDetail.types';
import * as S from './BetDetail.styled';

const BetDetail: React.FC<IBetDetail> = ({ id }) => {
  const theme: { main: string } = useTheme();
  const { id: betQueryId }: { id: string } = useParams();
  const { t } = useTranslation();
  const bet = useBets(s => s.bet_detail);
  const betId = id || bet.id || betQueryId;
  const error = useBets(s => s.betDetailError);
  const loading = useBets(s => s.detailLoading);
  const isScannerResultPage = includes(window.location.href || '', 'scanbet');
  const isDetailPage = isMatchWebRoutes(PageName.USER_BET_ITEM);
  const shouldRedirectToDesk = isDesktopView() && isDetailPage;
  const barcode = isScannerResultPage ? betQueryId : undefined;
  const userId = services.cookie.get(USER_ID);

  useEffect(() => {
    return (): void => {
      clearBetDetail();
    };
  }, []);

  let socket;
  let userChannel;

  useEffect(() => {
    if (!isScannerResultPage || !bet.id || userId === bet.user_id) {
      return;
    }

    socket = new Socket(`${services.config.get(BET_RM_SOCKET_URL)}`, {
      params: { barcode },
    });
    socket.connect();
    userChannel = socket.channel(`user:anonymous:bet:${bet.id}`);
    userChannel.join();

    userChannel.on('offer', setBetsSocketData);
  }, [bet?.id]);

  useEffect(
    () => () => {
      userChannel?.leave();
      socket?.disconnect();
    },
    [],
  );

  useEffect(() => {
    if (!isScannerResultPage || !bet.id) {
      getBetDetails({ bet_id: betId, barcode });
    }
  }, [betId]);

  useEffect(() => {
    if (shouldRedirectToDesk && betId) {
      setLocation(PageName.USER_BETS);
      setSelectedBetId(betId);
    }
  }, [shouldRedirectToDesk, betId]);

  return (
    <a.div style={{ ...useAnimatedX(ANIMATED_BET_DETAIL) }}>
      <S.Container
        className="betdetail"
        id={`betdetail-${betId}`}
        isTransparentDetail={shouldRedirectToDesk}
      >
        <div id="bet_detail">
          {(loading || shouldRedirectToDesk) && (
            <DualRingLoader backgroundColor={theme.main} />
          )}
          <>
            {keys(bet).length !== 0 && !loading && !shouldRedirectToDesk && (
              <BetDetailContent bet={bet} betId={betId} barcode={barcode} />
            )}

            {!keys(bet).length && !loading && error && (
              <GenericNotification iconType={INotificationIcon.error}>
                {t('bets.error_bet_detail')}
              </GenericNotification>
            )}
          </>
        </div>
      </S.Container>
    </a.div>
  );
};

export default BetDetail;
