import styled from 'styled-components';

import { getHeaderHeight } from '@features/app/layout/header';
import { footerHeight } from '@features/app/layout/footer';

import { SidebarSide } from '@common/interfaces';

const getStyleBySide = (side: SidebarSide): string => {
  switch (side) {
    case SidebarSide.center:
      return `
        align-items: center;
        justify-content: center;
      `;
    case SidebarSide.top:
      return `
        align-items: flex-start;
        justify-content: center;
      `;
    case SidebarSide.bottom:
      return `
        align-items: flex-end;
        justify-content: center;
      `;
    case SidebarSide.left:
      return `
        align-items: center;
        justify-content: flex-start;
      `;
    case SidebarSide.right:
      return `
        align-items: center;
        justify-content: flex-end;
      `;
    case SidebarSide.stretch:
      return `
        align-items: stretch;
        justify-content: center;
      `;

    default:
      return '';
  }
};

const getSidebarTop = applicationAdvBannerHeight =>
  `calc(${getHeaderHeight()}px + ${applicationAdvBannerHeight}px)`;

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div<{
  side: SidebarSide;
  preventDarkening?: boolean;
  isShowFooter?: boolean;
  fullScreen?: boolean;
  fullHeight?: boolean;
  applicationAdvBannerHeight?: number;
}>`
  ${({ fullHeight }) => fullHeight && 'height: 100%;'}
  position: fixed;
  top: ${({ fullScreen, applicationAdvBannerHeight }) =>
    fullScreen ? 0 : getSidebarTop(applicationAdvBannerHeight)};
  right: 0;
  bottom: ${({ theme, fullScreen, isShowFooter, preventDarkening }) =>
    theme.isDesktop ||
    fullScreen ||
    (!preventDarkening && !isShowFooter && !theme.isDesktop)
      ? 0
      : footerHeight}px;
  left: 0;
  z-index: ${(props): number => props.theme.zIndexSidebar};

  display: flex;

  ${({ preventDarkening }) =>
    !preventDarkening && 'background-color: rgba(0, 0, 0, .85);'}
  ${({ side }) => getStyleBySide(side)}
`;
