import CustomError from '@features/core/error/error';
import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';

import { UserEvents, MarketingEvents } from '@packages/events/appEvents';

import {
  TOKEN,
  SESSION_ID,
  USER_ID,
  MAIN_USER_ID,
  AUTO_LOGIN_ENABLED,
  IS_AUTOLOGIN_ENABLED,
} from '@common/constants/cookie';
import fetchLogin from '@common/api/user/fetchLogin';
import { setLocation } from '@common/providers/router/helper';
import authUser from '@common/providers/user/actions/authUser';
import switchWallets from '@common/helpers/paymentsHelper/switchWallets';
import {
  authUserSuccess,
  setLoginError,
  startUserAuth,
} from '@common/providers/user/useUserState';
import {
  createExpiresStamp,
  TWENTY_HOURS_IN_SEK,
} from '@common/helpers/deviceUtil';

const loginUser = async ({
  login,
  password,
}: {
  login: string;
  password: string;
}): Promise<void> => {
  startUserAuth();

  const response = await fetchLogin({
    login,
    password,
    lang: services.domainLang,
  });

  if ((response as { data: { is_mga: number } })?.data?.is_mga === 1) {
    window.location.href = `https://intl.sportwetten.de/#/top-leagues?sign-in&u=${login}`;
  }

  if (response instanceof CustomError) {
    setLoginError(response);
    return;
  }

  const { result } = response;

  if (result?.user_id) {
    services.events.emitEvent(UserEvents.LOGIN, { login, password });
    if (services.cookie.get(IS_AUTOLOGIN_ENABLED) === AUTO_LOGIN_ENABLED) {
      services.events.emitEvent(UserEvents.REQUEST_FACE_ID, null);
    }
    const { secret_token: token, session_id: session, user_id } = result;

    const expires = createExpiresStamp(TWENTY_HOURS_IN_SEK);
    services.cookie.set(TOKEN, token, { expires });
    services.cookie.set(SESSION_ID, session, { expires });
    services.cookie.set(USER_ID, user_id, { expires });
    services.cookie.set(MAIN_USER_ID, user_id, { expires });
    await authUser();
    services.events.emitEvent(MarketingEvents.USER_LOGIN, {});
    switchWallets();
  } else if (result?.go_for_passforget === 1) {
    setLocation(PageName.MIGRATION, {}, { search: `?email=${login}` });
    authUserSuccess(null);
  }
};

export default loginUser;
