import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing';
import { LMTTab } from '@features/detail/components/lmtTab';
import { SportTable } from '@features/detail/components/sportTable';
import { EventDetailAlarms } from '@features/detail/components/eventDetailAlarms';
import LastMatchesTab from '@features/detail/components/lastMatchesTab/LastMatchesTab';
import StatisticsIframeTab from '@features/detail/components/statisticsIframeTab/StatisticsIframeTab';
import services from '@features/core/services';

import { HAS_ALARMS } from '@common/constants/config';
import {
  hasSportradarIframeStatistics,
  hasSportradarWidgetLastMatches,
  hasSportradarWidgetLmt,
  hasSportradarWidgetTable,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import { IEventDetailWidgetTabs } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { useUserState } from '@common/providers/user/useUserState';
import {
  EventDetailStage,
  getDetailEventStage,
} from '@common/helpers/eventsHelper/eventDetailStageHelper';
import { DETAIL_PAGE_SCRIPT_ID } from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';

import { Icon } from '@ui/components/icon';
import GenericNotification from '@ui/components/genericNotification/GenericNotification';

import * as S from './EventDetailTabs.styled';
import { IEventDetailTabs } from './EventDetailTabs.types';

const EventDetailTabs: React.FC<IEventDetailTabs> = props => {
  const {
    event,
    category,
    isLMTWidgetAvailable,
    isLastMatchesWidgetAvailable,
  } = props;
  const [activeTab, setActiveTab] = useState('');
  const { t } = useTranslation();
  const selectedEvent = useEventsListState(state => state.detail.selectedEvent);
  const eventDetailState = useEventsListState(s => s.detail);
  const isStatisticsClick = selectedEvent?.isStatisticsTabOpen;
  const betradarId = event?.betradar_id || '';
  const activeStatisticsTab = hasSportradarWidgetLastMatches(event)
    ? IEventDetailWidgetTabs.lastMatches
    : IEventDetailWidgetTabs.statistics;
  const isLoggedIn = useUserState(state => state.isLoggedIn);

  const isStatisticsTabAvailable = (tabName: string): boolean => {
    if (
      !hasSportradarIframeStatistics(event, category) &&
      !hasSportradarWidgetLastMatches(event)
    ) {
      return false;
    }

    if (
      hasSportradarIframeStatistics(event, category) &&
      !hasSportradarWidgetLastMatches(event)
    ) {
      return tabName === IEventDetailWidgetTabs.statistics;
    }

    return tabName === IEventDetailWidgetTabs.lastMatches;
  };

  const eventEnded =
    getDetailEventStage(event, eventDetailState) === EventDetailStage.ENDED;
  useEffect(() => {
    if (eventEnded) {
      setActiveTab(IEventDetailWidgetTabs.lmt);
    }
  }, []);

  useEffect(() => {
    if (isStatisticsClick) {
      if (!eventEnded) {
        setActiveTab('');
      }
      setActiveTab(activeStatisticsTab);
    }
  }, [event.id, isStatisticsClick, activeStatisticsTab]);

  const handleTab = (tabName: string): void => {
    if (tabName === activeTab) {
      setActiveTab('');
    } else {
      setActiveTab(tabName);
    }
  };

  const renderTab = (tabName, iconName, tabText): React.ReactElement => {
    return (
      <S.Tab
        isActive={activeTab === tabName}
        onClick={(): void => handleTab(tabName)}
        className={activeTab === tabName ? 'active' : ''}
        data-qa={`${tabName}-tab-button`}
      >
        <S.TabTextWrapper>
          {iconName && (
            <S.IconHolder>
              <Icon name={iconName} width="12" height="12" />
            </S.IconHolder>
          )}
          <S.TabText>{tabText}</S.TabText>
        </S.TabTextWrapper>
      </S.Tab>
    );
  };

  return (
    <>
      <S.Tabs>
        {hasSportradarWidgetLmt(event, category) &&
          renderTab(IEventDetailWidgetTabs.lmt, '', 'Livematchtracker')}
        {isStatisticsTabAvailable(activeStatisticsTab) &&
          renderTab(activeStatisticsTab, '', t('common.labels.statistics'))}
        {hasSportradarWidgetTable(event, category, PageName.EVENTS_ITEM) &&
          renderTab(IEventDetailWidgetTabs.table, '', t('common.labels.table'))}
        {services.config.get(HAS_ALARMS) &&
          isLoggedIn &&
          renderTab(IEventDetailWidgetTabs.alarms, 'bell', 'Toralarm')}
      </S.Tabs>
      {activeTab === IEventDetailWidgetTabs.lmt &&
        hasSportradarWidgetLmt(event, category) && (
          <>
            {isLMTWidgetAvailable ? (
              <LMTTab
                betradarId={betradarId}
                key={event.id}
                scriptId={DETAIL_PAGE_SCRIPT_ID}
              />
            ) : (
              <GenericNotification>{t('events.no_lmt')}</GenericNotification>
            )}
          </>
        )}
      {activeTab === IEventDetailWidgetTabs.table &&
        isLMTWidgetAvailable &&
        hasSportradarWidgetTable(event, category, PageName.EVENTS_ITEM) && (
          <SportTable
            betradarId={betradarId}
            key={event.id}
            scriptId={DETAIL_PAGE_SCRIPT_ID}
          />
        )}
      {activeTab === IEventDetailWidgetTabs.lastMatches &&
        isStatisticsTabAvailable(IEventDetailWidgetTabs.lastMatches) && (
          <>
            {isLastMatchesWidgetAvailable ? (
              <LastMatchesTab
                betradarId={betradarId}
                key={event.id}
                scriptId={DETAIL_PAGE_SCRIPT_ID}
              />
            ) : (
              <GenericNotification>
                {t('events.no_last_matches')}
              </GenericNotification>
            )}
          </>
        )}
      {activeTab === IEventDetailWidgetTabs.statistics &&
        isStatisticsTabAvailable(IEventDetailWidgetTabs.statistics) && (
          <StatisticsIframeTab betradarId={betradarId} />
        )}
      {activeTab === IEventDetailWidgetTabs.alarms && (
        <EventDetailAlarms event={event} category={category} />
      )}
    </>
  );
};

export default EventDetailTabs;
