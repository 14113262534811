import services from '@features/core/services';

import { UserEvents } from '@packages/events/appEvents';

import {
  ACCEPT_TAC_LAYER_COOKIE,
  SUMMARY_LAYER_COOKIE,
  TOKEN,
  SESSION_ID,
  LUGAS_TIMER,
  REALITY_TIMER,
  IN_ACTIVITY_TIMER,
  USER_ID,
  MAIN_USER_ID,
  IS_LOGGED_IN,
  RESEND_CONFIRMATION,
  FAVORITES_CASHOUT_COOKIE,
  AUTH_TOKEN,
  VERIFICATION_NOTIFICATION_REMOVE,
  YEAR_VERIFICATION_NOTIFICATION_REMOVE,
  IS_YEARLY_DATA_CONFIRMED,
  HAS_ACCEPTED_GLOBAL_LIMITS,
  AFFILIATE_ID,
  SWITCHER_POPUP_CHECKED,
  ONLINE_ACCOUNT_NOTICE_REMOVE,
} from '@common/constants/cookie';
import fetchLogout from '@common/api/user/fetchLogout';
import {
  resetUserState,
  setEndedAuth,
  setLoggedInStatus,
} from '@common/providers/user/useUserState';
import {
  closeSidebars,
  removeNotifications,
} from '@common/providers/application/useAppState';
import { resetProfileState } from '@common/providers/profile/useProfile';
import { resetAccountState } from '@common/providers/account/useAccount';
import { resetTransactions } from '@common/providers/transactions/useTransactions';
import {
  resetBets,
  resetOpenBets,
  useBets,
  resetUpdates,
} from '@common/providers/bets/useBets';
import { resetBetsSocket } from '@common/providers/bets/helper';
import { resetWalletsState } from '@common/providers/payments/wallets/useWallets';
import { resetDefinedPaymentsState } from '@common/providers/payments/usePayments';
import { IPaymentStateTypes } from '@common/providers/payments/types';
import { resetLimits } from '@common/providers/limits/useLimits';

export const resetUserSessionData = (): void => {
  resetOpenBets();
  resetBets();
  resetUpdates();
  resetTransactions();
  resetLimits();
  resetUserState();
  resetAccountState();
  resetDefinedPaymentsState(IPaymentStateTypes.services);
  resetDefinedPaymentsState(IPaymentStateTypes.account);
  resetProfileState();
  resetWalletsState();
  setEndedAuth(false);
};

export const resetAuthCookies = (): void => {
  services.cookie.remove(TOKEN);
  services.cookie.remove(SESSION_ID);
  services.cookie.remove(AFFILIATE_ID);
  services.cookie.remove(AUTH_TOKEN);
  services.cookie.remove(USER_ID);
  services.cookie.remove(MAIN_USER_ID);
  services.cookie.remove(IS_LOGGED_IN);
  services.cookie.remove(RESEND_CONFIRMATION);
  services.cookie.remove(FAVORITES_CASHOUT_COOKIE);
  services.cookie.remove(LUGAS_TIMER);
  services.cookie.remove(REALITY_TIMER);
  services.cookie.remove(IN_ACTIVITY_TIMER);
  services.cookie.remove(ACCEPT_TAC_LAYER_COOKIE);
  services.cookie.remove(SUMMARY_LAYER_COOKIE);
  services.cookie.remove(VERIFICATION_NOTIFICATION_REMOVE);
  services.cookie.remove(YEAR_VERIFICATION_NOTIFICATION_REMOVE);
  services.cookie.remove(IS_YEARLY_DATA_CONFIRMED);
  services.cookie.remove(HAS_ACCEPTED_GLOBAL_LIMITS);
  services.cookie.remove(SWITCHER_POPUP_CHECKED);
  services.cookie.remove(ONLINE_ACCOUNT_NOTICE_REMOVE);
};

export const logoutFuncs = (): void => {
  const { socket } = useBets.getState();
  closeSidebars();
  resetUserSessionData();
  resetAuthCookies();
  resetBetsSocket(socket);
  removeNotifications();
};

const logoutUser = async (): Promise<void> => {
  // this line is duplicated with reset auth cookie as we want to show logged out state once user click logout button
  services.cookie.remove(IS_LOGGED_IN);
  setLoggedInStatus(false);
  try {
    services.events.emitEvent(UserEvents.LOGOUT, null);
    await fetchLogout();
  } catch (e) {
    const error = e as string;
    const errorWithResponse = e as { response: string };
    const logoutError =
      (errorWithResponse && errorWithResponse.response) || error;

    services.logger.error('logout', logoutError);
  } finally {
    logoutFuncs();
  }
};

export default logoutUser;
