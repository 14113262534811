import styled from 'styled-components';

export const MainAmount = styled.div`
  padding: ${({ theme }) => theme.space_10} 0;

  .partly-cashout-btn {
    margin-top: ${({ theme }) => theme.space_15};
  }

  .icon-close {
    margin: auto;
  }

  .input {
    width: 40%;
    margin-right: ${({ theme }) => theme.space_5};

    & > div {
      border: ${({ theme }) => theme.isDesktop && theme.border};
      border-radius: 3px;
    }
  }
`;

export const AmountButtonWrapper = styled.div<{ noGap: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr min-content;
  ${({ noGap }) => (noGap ? '' : 'grid-gap: 10px;')}

  button {
    max-width: 100vw;
    padding: ${({ theme }) => theme.space_10};
    justify-self: end;
  }
`;

export const AmountWrapper = styled.div<{
  isPartCashout: boolean;
  isReadySubMux: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  justify-items: end;
  grid-gap: ${({ theme }) => theme.space_10};

  ${({ isReadySubMux }) => isReadySubMux && 'grid-template-columns: 1fr;'};
  ${({ isPartCashout }) => isPartCashout && 'grid-auto-columns: 1fr auto;'};

  button {
    padding: ${({ theme }) => theme.space_10};
    grid-template-columns: 1fr;
  }

  .input {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    width: 55%;
  }

  .virtual_input {
    height: 34px;

    div {
      border-color: transparent transparent ${({ theme }) => theme.secondary}
        transparent;
    }
  }
`;

export const WrapKeyboard = styled.div`
  margin: ${({ theme }) => theme.space_10} -10px -5px;

  div::before,
  div::after {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

// todo: make PartCashoutContainer collapsible -> only functional
export const PartCashoutContainer = styled.div<{ extended?: boolean }>`
  padding: ${({ theme }) => theme.space_15} 0 0;
  ${props => !props.extended && 'display: none'};
`;
