import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';
import { footerHeight } from '@features/app/layout/footer';

export const Container = styled.div<{ isTransparentDetail: boolean }>`
  position: relative;
  width: 100%;
  min-width: 110px;

  #bet_detail {
    background-color: ${({ isTransparentDetail, theme }) =>
      isTransparentDetail ? 'transparent' : theme.main};
    ${({ theme }) =>
      theme.isDesktop &&
      'overflow-y: auto; height: calc(100vh - 60px); overflow-x: hidden;'}

    .bets-container {
      border-bottom: 5px solid ${props => props.theme.secondary};
      ${({ theme }) => theme.isDesktop && 'border: none;'}
    }
  }

  & .generic-scoreboard-last-part ul::after {
    content: ' ';
    box-shadow: rgb(0 0 0 / 35%) -8px 0 8px 4px;
    background: transparent;
    border-radius: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 18px;
    clip: rect(auto, -12px, auto, -20px);
  }

  .cashout-container {
    display: grid;
    color: ${({ theme }) => theme.highlightWrapperBackground};
    grid-auto-flow: column;
    height: 100%;
  }

  .cashout-reverse-bg {
    grid-auto-flow: row;
    background-color: ${({ theme }) => theme.textActive};
  }

  .betDetailCashoutButton {
    margin-top: ${({ theme }) => theme.space_15};
  }

  .part-cashout {
    margin-left: ${({ theme }) => theme.space_10};
    margin-right: ${({ theme }) => theme.space_10};
  }
`;

const getWithoutPaddingStyles = ({ theme }) =>
  `padding: 0px; h3 { padding: 0px ${theme.space_10}; }`;

export const DetailContainer = styled.div<{ withoutPadding?: boolean }>`
  padding: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10};
  border-top: 15px solid ${props => props.theme.secondary};
  border-bottom: 5px solid ${props => props.theme.secondary};
  background-color: ${({ theme }) => theme.textActive};

  ${({ withoutPadding }) => withoutPadding && getWithoutPaddingStyles};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const DetailHeader = styled.h3`
  display: grid;
  justify-content: start;
  grid-auto-columns: 1fr;
  align-items: center;
  margin: ${({ theme }) => theme.space_10} 0 ${({ theme }) => theme.space_20};
  font-size: ${({ theme }) => (theme.isDesktop ? theme.fontMD : theme.fontLG)};
  color: ${props => props.theme.textMain};
  ${TextNormal};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const DetailList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${props => props.theme.elementNuanceColor};

  li:nth-child(2n) {
    background: ${props => props.theme.textActive};
  }
`;

export const DetailListItem = styled.li<{
  userSelect?: boolean;
  withDivider?: boolean;
}>`
  margin: 0;
  padding: ${({ theme }) => theme.space_5};
  padding-bottom: ${({ withDivider, theme }) => withDivider && theme.space_15};

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.textMain};
  position: relative;
  border-bottom: ${({ theme }) => theme.border};

  > b {
    user-select: ${props => props.userSelect && 'text'};
  }

  & > div {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
  }
`;

export const DividerLine = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.space_1};
  background-color: ${props => props.theme.textMain};
`;

export const Divider = styled(DividerLine)``;

export const DoubleDivider = styled.div`
  margin: ${({ theme }) => theme.space_5} 0;
  width: 100%;
  height: ${({ theme }) => theme.space_1};
  border-top: 1px solid ${props => props.theme.textMain};
  border-bottom: 1px solid ${props => props.theme.textMain};
`;

export const DetailClose = styled(TextNormal)`
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 78px;
  ${TextNormal};

  color: ${props => props.theme.textMain};
  background-color: ${props => props.theme.textActive};
  margin-bottom: ${({ theme }) => theme.space_5};

  svg {
    margin-bottom: ${({ theme }) => theme.space_5};
  }
`;

export const PageHeaderWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  height: 30px;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.textMain};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const CashoutInputsWrapper = styled.div`
  scroll-margin-bottom: ${footerHeight}px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;
